@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.content{
  margin-top: 85px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.customizeButton{
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: #FF4D0073;
  background-color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 21.6px;
  color: #000000;
  cursor: pointer;
  padding: 16px 28px;
  border-radius: 32px;
  margin: 30px 0px;
  transition: all 0.2s ease-in-out;
}
.customizeButton:hover{
  background-color: #0b4c7c;
  color: #fff;
}